.contact {
    @include flex-column-item;
    @include gradient-left_bottom;
    @include glow-shadows-bottomInside;
    width: 100%;
    padding: 80px;
    gap: 50px;
    @include bp-600 {
        padding: 50px 30px;
    }
    &_form {
        @include flex-column;
        gap: 15px;
        & input, textarea {
            @include montserrat-regular;
            background-color: $white;
            color: $black;
            padding: 10px;
            border-radius: 10px;
            font-size: 1.2rem;
            width: 500px;
            @include bp-600 {
                font-size: 1rem;
                width: 80vw;
            }
        }
        & textarea {
            height: 200px;
        }
        & button {
            @include montserrat-bold;
            font-size: 1.2rem;
            padding: 10px 15px;
            border-radius: 10px;
            color: $black;
            width: max-content;
            border: 1px solid $white;
            transition: transform .2s;
            @include bp-600 {
                font-size: 1rem;
            }
            &:hover {
                @include glow-shadows-background;
                color: $white;
                transform: scale(1.1);
            }
        }
    }
}
.footer {
    @include flex-column-item-content;
    width: 100%;
    padding: 40px;
    gap: 20px;
    background-color: $black;
    &_icon {
        @include flex;
        gap: 20px;
        & img {
            border: 1px solid $white;
            border-radius: 50%;
            width: 50px;
            &:hover {
                @include glow-shadows;
            }
        }
    }
    &_txt {
        @include montserrat-regular;
        text-align: center;
        font-size: 1.2rem;
    }
}
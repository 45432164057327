.skills {
    @include flex-column-item-content;
    @include glow-shadows;
    border-left: 0px;
    border-right: 0px;
    width: 100%;
    background-color: $black;
    padding: 100px 0px;
    &_container {
        display: grid;
        justify-items: end;
        grid-template-columns: repeat(4, 1fr);
        gap: 50px 50px;
        width: 80%;
        max-width: 1440px;
        @include bp-1400 {
            grid-template-columns: repeat(3, 1fr)
        }
        @include bp-860 {
            grid-template-columns: repeat(2, 1fr);
            gap: 30px 30px;
        }
        @include bp-600 {
            gap: 20px 10px;
        }
    }
    &_icon {
        @include flex-item;
        border-radius: 20px;
        gap: 22px;
        min-height: 70px;
        width: 80%;
        min-width:230px;
        max-width: 230px;
        transition: transform .5s;
        border: 1px solid $black;
        @include bp-1000 {
            gap: 15px;
            min-width: 180px;
            max-width: 200px;
            min-height: 50px;
        }
        @include bp-600 {
            min-width: 100px;
            max-width: 160px;
        }
        &:hover {
            @include glow-shadows-background;
            transform: scale(1.2);
            @include bp-600 {
                transform: scale(1);
                background-color: $black;
                border: none;
                box-shadow: none;
            }
            & img {
                transform: scale(1.3);
                @include bp-1000 {
                    transform: scale(1rem);
                }
            }
            & h3 {
                font-size: 1.5rem;
                @include bp-1000 {
                    font-size: 1.2rem;
                }
            }
        }
        & img {
            width: 70px;
            @include glow-shadows-background;
            @include icon-border;
            transition: transform .2s ease-in-out;
            @include bp-1000 {
                width: 60px
            }
            @include bp-600 {
                width: 40px
            }
        }
        & h3 {
            @include montserrat-bold;
            transition: transform .5s;
            font-size: 1.4rem;
            @include bp-1000 {
                font-size: 1rem;
            }
        }
    }
}
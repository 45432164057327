.card {
    @include flex-column;
    background-color: $primary-blue;
    max-width: 430px;
    width: 100%;
    border: 1px solid $white;
    border-radius: 20px;
    transition: transform .2s;
    @include bp-1400 {
        max-width: 400px;
    }
    @include bp-860 {
        max-width: 900px;
    }
    cursor: pointer;
    &:hover {
        @include glow-shadows;
        transform: scale(1.3);
        @include bp-1000 {
            transform: scale(1.2);
        }
        @include bp-600 {
            transform: scale(1.1);
        }
    }
    &__image {
        @include flex-column;
        object-fit: contain;
        & img {
            border-radius: 20px 20px 0px 0px;
            width: 100%;
        }
    }
    &__content {
        @include flex-column;
        padding: 15px;
    }
    &__header {
        @include flex-item;
        justify-content: space-between;
    }
    &__title {
        & h3 {
            font-size: 1.5rem;
            @include bp-600 {
                font-size: 1.3rem;
            }
        }
    }
    &__icon-list {
        @include flex-item;
        gap: 5px;
        & img {
            @include icon-border;
            width: 30px;
            @include bp-600 {
                width: 25px;
            }
        }
    }
    &__description {
        @include montserrat-regular;
        padding: 15px;
    }
}
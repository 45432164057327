* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    color: $white;
    & h2 {
        @include montserrat-bold;
        font-size: 3rem;
        @include bp-1000 {
            font-size: 2rem;
        }
        @include bp-500 {
            font-size: 1.5rem;
        }
    }
    & h3 {
        @include montserrat-bold;
    }
    & h4 {
        @include montserrat-bold;
    }
    & li {
        list-style: none;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
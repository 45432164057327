// Montserrat
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@mixin montserrat-regular {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-optical-sizing: auto;
}
@mixin montserrat-bold {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-optical-sizing: auto;
}

// Bungee 
@import url('https://fonts.googleapis.com/css2?family=Bungee+Inline&display=swap');
@mixin bungee {
    font-family: "Bungee Inline", sans-serif;
    font-weight: 400;
    font-style: normal;
}
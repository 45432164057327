.header {
    @include glow-shadows-bottom;
    z-index: 10;
    position: fixed;
    width: 100%;
    background-color: $black;
    &-container {
        @include flex-item;
        justify-content: space-between;
        max-width: 1440px;
        margin: auto;
        @include bp-1000 {
            flex-direction: column;
        }
    }
    &_logo {
        @include bungee;
        font-size: 2.5rem;
        line-height: 2.5rem;
        padding: 30px 0px ;
        margin-left: 10px;
        @include bp-1000 {
            padding: 20px 0px;
            margin: 0px;
        }
        @include bp-500 {
            font-size: 2rem;
            line-height: 1rem;
        }
    }
    &_nav {
        display: flex;
        gap: 10px;
        margin-right: 20px;
        @include bp-1000 {
            margin: 0px;
        }
        @include bp-500 {
            gap: 5px;
        }
        &-link {
            @include montserrat-regular;
            font-size: 1.5rem;
            border: 1px solid $black;
            padding: 5px 10px;
            border-radius: 15px;
            transition: background .2s, box-shadow .3s ease-in-out;
            @include bp-1000 {
                font-size: 1.2rem;
                padding: 5px 10px;
                margin-bottom: 15px;
            }
            @include bp-500 {
                font-size: 1rem;
                line-height: 1rem;
                padding: 5px;
            }
            &:hover {
                @include glow-shadows-background;
                transform: scale(1.1);
            }
        }
    }
}
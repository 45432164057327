.projects {
    @include flex-column-item;
    @include gradient-right_top;
    @include glow-shadows-topInside;
    width: 100%;
    height: 100%;
    padding-top: 150px;
    padding-bottom: 150px;
    gap: 100px 0px;
    @include bp-1400 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @include bp-860 {
        padding-top: 80px;
        padding-bottom: 200px;
        gap: 80px 0px;
    }
    @include bp-500 {
        padding-bottom: 150px;
    }
    & h2 {
        text-align: center;
        width: 90%;
    }
    &__cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10% 5%;
        @include bp-1400 {
            grid-template-columns: 1fr 1fr;
            gap: 5%;
        }
        @include bp-860 {
            grid-template-columns: 1fr;
            gap: 2%;
            max-width: 80vw;
        }
    }
}
// Flex
@mixin flex {
    display: flex;
}
@mixin column {
    flex-direction: column;
}
@mixin item-center {
    align-items: center;
}
@mixin content-center {
    justify-content: center;
}

// Combine
@mixin flex-column {
    @include flex;
    @include column
}
@mixin flex-item {
    @include flex;
    @include item-center
}
@mixin flex-content {
    @include flex;
    @include content-center;
}
@mixin flex-column-item {
    @include flex-item;
    @include column
}
@mixin flex-item-content {
    @include flex-item;
    @include content-center;
}
@mixin flex-column-item-content {
    @include flex-column-item;
    @include content-center;
}
@mixin flex-item-between {
    @include flex-item;
    justify-content: space-between;
}

// Breakpoint
@mixin bp-1400 {
    @media screen and (max-width: 1400px) {
        @content;
    }
}
@mixin bp-1150 {
    @media screen and (max-width: 1195px) {
        @content;
    }
}
@mixin bp-1000 {
    @media screen and (max-width: 1000px) {
        @content;
    }
}
@mixin bp-860 {
    @media screen and (max-width: 860px) {
        @content;
    }
}
@mixin bp-600 {
    @media screen and (max-width: 600px) {
        @content;
    }
}
@mixin bp-500 {
    @media screen and (max-width: 500px) {
        @content;
    }
}